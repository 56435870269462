import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import formErros from "./modules/formErros";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
// import SvgUse from "./utils/SvgUse";
// Config


UseMouseOrKeyboard();
formErros();
animateSetup();
animaAoScroll();

// utils logs
